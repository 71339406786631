@import 'shared/shared';
@import './node_modules/@nbcnews/design-tokens/tokens/today/breakpoints';
@import './node_modules/@nbcnews/design-tokens/tokens/today/colors';
@import './node_modules/@nbcnews/design-tokens/tokens/today/colorsRGB';
@import './node_modules/@nbcnews/design-tokens/tokens/today/fontFamilies';
@import './node_modules/@nbcnews/design-tokens/tokens/today/fontSizes';
@import './node_modules/@nbcnews/design-tokens/tokens/today/fontWeights';
@import './node_modules/@nbcnews/design-tokens/tokens/today/gradients';
@import './node_modules/@nbcnews/design-tokens/tokens/today/letterSpacings';
@import './node_modules/@nbcnews/design-tokens/tokens/today/lineHeights';
@import './node_modules/@nbcnews/design-tokens/tokens/today/opacities';
@import './node_modules/@nbcnews/design-tokens/tokens/today/spacing';
// Redesign tokens
@import './node_modules/@nbcnews/design-tokens/tokens/today2/colors';
@import './node_modules/@nbcnews/design-tokens/tokens/today2/colorsRGB';
@import './node_modules/@nbcnews/design-tokens/tokens/today2/fontFamilies';
// Helper
@import '../utils/functions';

// Today Fonts
// --primary-font : Prata
// --secondary-font : Mada
// --tertiary-font : CrimsonPro

// GLOBAL VARIABLES
$theme: 'today';

// Background
$default-background: #fff;

// Colors
$default-color: $primary-color; // Historically: vermillion #ff503c
$default-neutral: $white;

// Gradient
$default-gradient: to-list($orange-purple-gradient);
$gradient-value-1: #ff503c;
$gradient-value-2: #8d048d;

// Images
$default-img-border-radius: 50%;

// Links
$default-hover-opacity: 1;
$default-active-opacity: 1;

// Headlines
$default-headline-color: $secondary-70;
$default-headline-leading: $leading-150;
$default-headline-font-family: $secondary-font;

// Body text
$default-text-size: $text-18;
$default-text-color: $grey-70;
$default-text-leading: $leading-150;
$default-text-font-family: $tertiary-font;

// Header
$default-local-insert-flex-basis: 80px;

:root {
  /* Colors */
  --default-color: var(--primary-color);
  --default-color-rgb: var(--primary-color-rgb);
  --default-neutral: var(--white);
  --default-neutral-rgb: var(--white-rgb);

  /* Gradient */
  --default-gradient: var(--orange-purple-gradient-stop-1), var(--orange-purple-gradient-stop-2), var(--orange-purple-gradient-stop-3);
  --gradient-value-1: #ff503c;
  --gradient-value-1-rgb: 255, 80, 60;
  --gradient-value-2: #8d048d;
  --gradient-value-2-rgb: 141, 4, 141;

  /* Images */
  --default-img-border-radius: 50%;

  /* Links */
  --default-hover-opacity: 1;
  --default-active-opacity: 1;

  /* Headlines */
  --default-headline-color: var(--secondary-70);
  --default-headline-color-rgb: var(--secondary-70-rgb);
  --default-headline-leading: var(--leading-150);
  --default-headline-font-family: var(--secondary-font);

  /* Body text */
  --default-text-size: var(--text-18);
  --default-text-color: var(--grey-70);
  --default-text-color-rgb: var(--grey-70-rgb);
  --default-text-leading: var(--leading-150);
  --default-text-font-family: var(--tertiary-font);

  /* Header */
  --default-local-insert-flex-basis: 80px;
}

body.today {
  /* Default overrides */
  --package-spacing: 45px;
}
